<template>
  <div id="game-demo">
    <section class="hero is-link mnc-gradient">
      <div class="hero-body">
        <p class="title">Demo Slot Terbaik</p>
        <p class="subtitle">Daftar review slot gacor dan maxwin</p>
      </div>
    </section>
    <section class="section">
      <div
        class="columns game-columns is-1-mobile is-3-desktop is-desktop is-mobile is-multiline is-flex"
      >
        <div
          v-for="(item, index) in data"
          :key="index"
          class="column is-4-desktop is-4-tablet is-12-mobile"
          @click="redirectTo(item.id)"
        >
          <div class="card is-flex-grow-1 game-demo-card">
            <div class="card-image">
              <figure class="image is-4by3">
                <img :src="item.picture" />
              </figure>
            </div>
            <div class="card-content">
              <span class="tag is-danger mb-4">Demo Slot</span>
              <p class="is-size-6">
                {{
                  new Date(item.createdAt).toLocaleString("id-ID", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                }}
              </p>
              <div class="content has-text-left">
                <b class="is-size-5">{{ item.name }}</b>
                <p>{{ item.excerpt }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import GameDemoServices from "@/services/game_demo_services";

export default {
  name: "Home",
  components: {},
  setup() {
    const route = useRoute();
    const data = ref([
      {
        id: undefined,
        name: undefined,
        picture: undefined,
        demo_url: undefined,
        narrative: undefined,
        provider_id: undefined,
        provider: {
          id: undefined,
          provider: undefined,
          icon: undefined,
        },
        createdAt: new Date(),
      },
    ]);

    const mountScreen = async () => {
      await fetchDataFromServer();
    };

    onMounted(async () => {
      mountScreen();
    });

    const fetchDataFromServer = async () => {
      let response = await GameDemoServices.getAll(1, 200);
      data.value = response.data.data.rows;
    };

    const redirectTo = (id) => {
      router.push({ name: "GameDemoView", params: { id } });
    };

    return {
      route,
      data,
      redirectTo,
    };
  },
};
</script>

<style>
.game-demo-card {
  border-radius: 1em;
}

.mnc-gradient {
  border-top: 1em solid black;
  background-image: linear-gradient(to bottom right, #ffab00, #ffe000, #ffb100);
  color: black !important;
}

.mnc-gradient p {
  color: black !important;
}
</style>
